import __NS_DYNAMIC_REACT_CONTENT___0 from "./env-chooser.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { FormSubmitButton } from 'legos/button/form-submit';
import { ButtonRaised } from 'legos/button/raised';
import { SystemIcon } from 'legos/system-icon';
import { UnknownField } from 'legos/text-field/unknown';
import { Helper, Disclosure } from 'legos/typography';
import { useLocation } from 'packages/react-nano-router';
import { createElement, Fragment } from 'react';
import { Form } from 'react-final-form';
import { useEnvChooserContext } from './context';
import { Container, MarginTop } from './styles/env-chooser.ccm.css';
import { CenterChildren } from 'apps/acp/packages/center-children';
export const EnvChooser = () => {
  const {
    href
  } = useLocation();
  const {
    setEnvChooserVisible
  } = useEnvChooserContext();
  return createElement(Fragment, null, createElement(Disclosure, {
    style: {
      margin: '10px',
      float: 'right'
    },
    title: "closeX",
    onClick: () => setEnvChooserVisible(false)
  }, createElement(SystemIcon, {
    type: "closeX",
    size: 2.0
  })), createElement(Container.div, null, createElement(CenterChildren, null, createElement(Helper, null, createElement(Content, {
    hash: "d9a890b16cfaafbb6ab0bc1ecf1d7f9b",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Helper, {
    style: {
      wordBreak: 'break-all'
    }
  }, href), createElement(Helper, null, createElement(Content, {
    hash: "9de6255827be60acdbf52a839df5cde3",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Helper, {
    style: {
      wordBreak: 'break-all'
    }
  }, createElement(Content, {
    hash: "900c94da4fb11a23666ae2482f1d1408",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))), createElement(Form, {
    onSubmit: values => window.location.assign(values.environment)
  }, ({
    handleSubmit
  }) => createElement("form", {
    onSubmit: handleSubmit,
    noValidate: true
  }, createElement(UnknownField, {
    name: "environment",
    label: "Enter an Environment",
    required: true,
    errorRequiredMessage: "Required"
  }), createElement(MarginTop.div, null, createElement(CenterChildren, null, createElement(FormSubmitButton, {
    variant: ButtonRaised,
    color: "positive"
  }, createElement(Content, {
    hash: "8cc5dd5b3da0b6c91b666e0438b57a0d",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))))))));
};